import { Link } from 'gatsby'
import React, { memo } from 'react'
import logoDefault from '../../theme/media/logo-default.svg'
import logoSticky from '../../theme/media/logo-sticky.svg'

const Header = () => (
  <header className='sticky-header menu-layout1'>
    <div className='container'>
      <div className='row d-flex align-items-center'>
        <div className='col-lg-2'>
          <div className='logo-area'>
            <Link to='/' className='default-logo'>
              <img src={logoDefault} alt='logo' className='img-fluid' />
            </Link>
            <Link to='/' className='sticky-logo'>
              <img src={logoSticky} alt='logo' className='img-fluid' />
            </Link>
          </div>
        </div>
        <div className='col-lg-10 d-flex justify-content-end'>
          <nav id='dropdown' className='template-main-menu'>
            <ul id='onepage_nav'>
              <li className='current'>
                <a href='#home'>Home</a>
              </li>
              <li>
                <a href='#about'>About</a>
              </li>
              <li>
                <a href='#skills'>Skills</a>
              </li>
              <li>
                <a href='#services'>Services</a>
              </li>
              <li>
                <a href='#portfolio'>Portfolio</a>
              </li>
              <li>
                <a href='#pricing'>Pricing</a>
              </li>
              <li>
                <a href='#contact'>Contact</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>
)

export default memo(Header)
