/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { memo } from 'react'
import map from '../../theme/media/figure/map.png'

const Contact = () => (
  <section className='fxt-contact-section bg-color-1' id='contact' data-bg-image={map}>
    <div className='container'>
      <div className='fxt-heading-layout1'>
        <h2 className='heading-title'>Contact Me</h2>
        <div className='heading-paragraph'>
          Need a developer, a team, some technical expertise or just advices… ? I would be happy to
          answer all your questions.
        </div>
        <div className='heading-line' />
      </div>
      <div className='row'>
        <div className='col-lg-6 col-12'>
          <div className='fxt-contact-box-layout1'>
            <div className='contact-list'>
              <div className='item-icon'>
                <i className='flaticon-placeholder' />
              </div>
              <div className='item-content'>
                <h3 className='item-title'>Address</h3>
                <div className='item-text'>14 rue Charles V</div>
                <div className='item-text'>75004 Paris</div>
              </div>
            </div>
            <div className='contact-list'>
              <div className='item-icon'>
                <i className='flaticon-email' />
              </div>
              <div className='item-content'>
                <h3 className='item-title'>Email:</h3>
                <div className='item-text'>vianney.carel@logfuse.fr</div>
              </div>
            </div>
            {/* <div className='contact-list' style={{ visibility: 'hidden' }}>
              <div className='item-icon'>
                <i className='flaticon-telephone' />
              </div>
              <div className='item-content'>
                <h3 className='item-title'>Phone</h3>
                <div className='item-text'>***</div>
              </div>
            </div> */}
          </div>
        </div>
        <div className='col-lg-6 col-12'>
          <div className='fxt-contact-box-layout2'>
            <form method='POST' id='contact-form'>
              <input
                type='checkbox'
                name='_honeypot'
                style={{ display: 'none' }}
                tabIndex='-1'
                autoComplete='off'
              />
              <div className='row'>
                <div className='col-lg-12 form-group'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Full Name'
                    name='name'
                    data-error='Name field is required'
                    required
                  />
                  <div className='help-block with-errors' />
                </div>
                <div className='col-lg-6 form-group'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='E-mail'
                    name='email'
                    data-error='Email field is required'
                    required
                  />
                  <div className='help-block with-errors' />
                </div>
                <div className='col-lg-6 form-group'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Subject'
                    name='subject'
                    data-error='Subject field is required'
                    required
                  />
                  <div className='help-block with-errors' />
                </div>
                <div className='col-lg-12 form-group'>
                  <textarea
                    name='message'
                    id='form-message'
                    className='form-control textarea'
                    placeholder='Your Message'
                    cols='30'
                    rows='7'
                    data-error='Message field is required'
                    required
                  />
                  <div className='help-block with-errors' />
                </div>
                <div className='col-lg-12 form-group'>
                  <button type='submit' className='btn-fill submit-btn'>
                    SEND MESSAGE
                  </button>
                </div>
              </div>
              <div className='form-response' />
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default memo(Contact)
