import React from 'react'
import About from '../components/about'
import Banner from '../components/banner'
import Contact from '../components/contact'
import Footer from '../components/footer'
import Header from '../components/header'
import Portfolio from '../components/portfolio'
import Pricing from '../components/pricing'
import Progress from '../components/progress'
import Seo from '../components/seo'
import Services from '../components/services'
import Skills from '../components/skills'
import Testimonials from '../components/testimonials'
import { useThemeScripts } from '../hooks/theme'

const IndexPage = () => {
  useThemeScripts()

  return (
    <>
      <Seo />

      <div id='home' className='wrapper'>
        <Header />

        <Banner />

        <About />

        <Skills />

        <Services />

        <Testimonials />

        <Portfolio />

        <Progress />

        <Pricing />

        <Contact />

        <Footer />
      </div>
    </>
  )
}

export default IndexPage
