import React, { memo } from 'react'

const Footer = () => (
  <footer className='fxt-footer-section bg-color-2'>
    <div className='container'>
      <div className='fxt-footer-box-layout1'>
        <div className='copyright-text'>©2020 Logfuse. All Rights Reserved.</div>
      </div>
    </div>
  </footer>
)

export default memo(Footer)
