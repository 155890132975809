import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React, { memo } from 'react'
import portfolio1 from '../images/portfolio1.jpg'
import portfolio2 from '../images/portfolio2.jpg'
import portfolio3 from '../images/portfolio3.jpg'
import portfolio4 from '../images/portfolio4.jpg'
import portfolio5 from '../images/portfolio5.png'

const Portfolio = () => {
  const data = useStaticQuery(graphql`
    query {
      portfolio1: file(relativePath: { eq: "portfolio1.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      portfolio2: file(relativePath: { eq: "portfolio2.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      portfolio3: file(relativePath: { eq: "portfolio3.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      portfolio4: file(relativePath: { eq: "portfolio4.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      portfolio5: file(relativePath: { eq: "portfolio5.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <section className='fxt-portfolio-section bg-color-1' id='portfolio'>
      <div className='container'>
        <div className='fxt-heading-layout1'>
          <h2 className='heading-title'>Portfolio</h2>
          <div className='heading-paragraph'>
            <em>“Design is not just what it looks like and feels like. Design is how it works.”</em>
            <br />
            Steve Jobs
          </div>
          <div className='heading-line' />
        </div>

        <div className='isotope-wrap'>
          <div className='row featuredContainer'>
            <div className='col-lg-4 col-md-6 col-sm-6 col-12 mockups has-animation'>
              <div className='translate-left-10 opacity-animation transition-200 transition-delay-100'>
                <div className='fxt-portfolio-box-layout1'>
                  <div className='item-img'>
                    <Img fluid={data.portfolio1.childImageSharp.fluid} alt='Portfolio' />
                    <div className='item-content'>
                      <div className='item-icon'>
                        <a
                          href={portfolio1}
                          className='popup-zoom'
                          data-fancybox-group='gallery'
                          data-mfp-src={portfolio1}
                        >
                          <i className='fas fa-images' />
                        </a>
                      </div>
                      <h3 className='item-title'>
                        <a
                          href='https://www.pathoquest.com'
                          rel='noreferrer noopener'
                          target='_blank'
                        >
                          PATHOQUEST
                        </a>
                      </h3>
                      <div className='item-paragraph'>5 React + Python + MongoDB Apps</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-6 col-12 web has-animation'>
              <div className='translate-left-10 opacity-animation transition-200 transition-delay-400'>
                <div className='fxt-portfolio-box-layout1'>
                  <div className='item-img'>
                    <Img fluid={data.portfolio2.childImageSharp.fluid} alt='Portfolio' />
                    <div className='item-content'>
                      <div className='item-icon'>
                        <a
                          href={portfolio2}
                          className='popup-zoom'
                          data-fancybox-group='gallery'
                          data-mfp-src={portfolio2}
                        >
                          <i className='fas fa-images' />
                        </a>
                      </div>
                      <h3 className='item-title'>
                        <a href='https://www.onepark.fr' rel='noreferrer noopener' target='_blank'>
                          ONEPARK
                        </a>
                      </h3>
                      <div className='item-paragraph'>Fullstack React+Ruby Development</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-6 col-12 photography has-animation'>
              <div className='translate-left-10 opacity-animation transition-200 transition-delay-700'>
                <div className='fxt-portfolio-box-layout1'>
                  <div className='item-img'>
                    <Img fluid={data.portfolio3.childImageSharp.fluid} alt='Portfolio' />
                    <div className='item-content'>
                      <div className='item-icon'>
                        <a
                          href={portfolio3}
                          className='popup-zoom'
                          data-fancybox-group='gallery'
                          data-mfp-src={portfolio3}
                        >
                          <i className='fas fa-images' />
                        </a>
                      </div>
                      <h3 className='item-title'>WEEZHOME</h3>
                      <div className='item-paragraph'>CTO / Fullstack Development</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-6 col-12 photography has-animation'>
              <div className='translate-left-10 opacity-animation transition-200 transition-delay-1300'>
                <div className='fxt-portfolio-box-layout1'>
                  <div className='item-img'>
                    <Img fluid={data.portfolio5.childImageSharp.fluid} alt='Portfolio' />
                    <div className='item-content'>
                      <div className='item-icon'>
                        <a
                          href={portfolio5}
                          className='popup-zoom'
                          data-fancybox-group='gallery'
                          data-mfp-src={portfolio5}
                        >
                          <i className='fas fa-images' />
                        </a>
                      </div>
                      <h3 className='item-title'>
                        <a href='https://www.quortex.io' rel='noreferrer noopener' target='_blank'>
                          QUORTEX
                        </a>
                      </h3>
                      <div className='item-paragraph'>
                        React.js + Highcharts and other fancy libs
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-6 col-12 photography has-animation'>
              <div className='translate-left-10 opacity-animation transition-200 transition-delay-1000'>
                <div className='fxt-portfolio-box-layout1'>
                  <div className='item-img'>
                    <Img fluid={data.portfolio4.childImageSharp.fluid} alt='Portfolio' />
                    <div className='item-content'>
                      <div className='item-icon'>
                        <a
                          href={portfolio4}
                          className='popup-zoom'
                          data-fancybox-group='gallery'
                          data-mfp-src={portfolio4}
                        >
                          <i className='fas fa-images' />
                        </a>
                      </div>
                      <h3 className='item-title'>
                        <a
                          href='https://github.com/vcarel/load-monitor-meteor'
                          rel='noreferrer noopener'
                          target='_blank'
                        >
                          DEMO APP
                        </a>
                      </h3>
                      <div className='item-paragraph'>Dev Challenge On Meteor.js</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default memo(Portfolio)
