import { useEffect } from 'react'
import $ from 'jquery'
import logoSticky from '../../theme/media/logo-sticky.svg'

export const useThemeScripts = () => {
  useEffect(() => {
    window.$ = window.jQuery = $

    /* -------------------------------------
    Carousel Slider Initiation
    ------------------------------------- */
    import('../../theme/dependencies/owl.carousel/js/owl.carousel.min.js').then(() => {
      $('.rc-carousel').each(function () {
        const carousel = $(this)
        const loop = carousel.data('loop')
        const Canimate = carousel.data('animate')
        const items = carousel.data('items')
        const margin = carousel.data('margin')
        const autoplay = carousel.data('autoplay')
        const autoplayTimeout = carousel.data('autoplay-timeout')
        const smartSpeed = carousel.data('smart-speed')
        const dots = carousel.data('dots')
        const nav = carousel.data('nav')
        const navSpeed = carousel.data('nav-speed')
        const rXsmall = carousel.data('r-x-small')
        const rXsmallNav = carousel.data('r-x-small-nav')
        const rXsmallDots = carousel.data('r-x-small-dots')
        const rXmedium = carousel.data('r-x-medium')
        const rXmediumNav = carousel.data('r-x-medium-nav')
        const rXmediumDots = carousel.data('r-x-medium-dots')
        const rSmall = carousel.data('r-small')
        const rSmallNav = carousel.data('r-small-nav')
        const rSmallDots = carousel.data('r-small-dots')
        const rMedium = carousel.data('r-medium')
        const rMediumNav = carousel.data('r-medium-nav')
        const rMediumDots = carousel.data('r-medium-dots')
        const rLarge = carousel.data('r-large')
        const rLargeNav = carousel.data('r-large-nav')
        const rLargeDots = carousel.data('r-large-dots')
        const rExtraLarge = carousel.data('r-extra-large')
        const rExtraLargeNav = carousel.data('r-extra-large-nav')
        const rExtraLargeDots = carousel.data('r-extra-large-dots')
        const center = carousel.data('center')
        const customNav = carousel.data('custom-nav') || ''
        carousel.addClass('owl-carousel')

        const owl = carousel.owlCarousel({
          loop: !!loop,
          animateOut: Canimate,
          items: items || 1,
          lazyLoad: true,
          margin: margin || 0,
          autoplay: !!autoplay,
          autoplayTimeout: autoplayTimeout || 1000,
          smartSpeed: smartSpeed || 250,
          dots: !!dots,
          nav: !!nav,
          navText: [
            '<i class="fa fa-angle-left" aria-hidden="true"></i>',
            '<i class="fa fa-angle-right" aria-hidden="true"></i>'
          ],
          navSpeed: !!navSpeed,
          center: !!center,
          responsiveClass: true,
          responsive: {
            0: {
              items: rXsmall || 1,
              nav: !!rXsmallNav,
              dots: !!rXsmallDots
            },
            576: {
              items: rXmedium || 2,
              nav: !!rXmediumNav,
              dots: !!rXmediumDots
            },
            768: {
              items: rSmall || 3,
              nav: !!rSmallNav,
              dots: !!rSmallDots
            },
            992: {
              items: rMedium || 4,
              nav: !!rMediumNav,
              dots: !!rMediumDots
            },
            1200: {
              items: rLarge || 5,
              nav: !!rLargeNav,
              dots: !!rLargeDots
            },
            1240: {
              items: rExtraLarge || 5,
              nav: !!rExtraLargeNav,
              dots: !!rExtraLargeDots
            }
          }
        })

        if (customNav) {
          const nav = $(customNav)
          const navNext = $('.rt-next', nav)
          const navPrev = $('.rt-prev', nav)

          navNext.on('click', function (e) {
            e.preventDefault()
            owl.trigger('next.owl.carousel')
            return false
          })

          navPrev.on('click', function (e) {
            e.preventDefault()
            owl.trigger('prev.owl.carousel')
            return false
          })
        }
      })
    })

    /* -------------------------------------
    MeanMenu Activation Code
    -------------------------------------- */
    import('../../theme/dependencies/meanmenu/js/jquery.meanmenu.min.js').then(() => {
      $('nav#dropdown').meanmenu({
        meanMenuOpen: '<span></span><span></span><span></span>',
        siteLogo: `<div class='mobile-menu-nav-back'><a class='logo-mobile' href='index.html'><img src='${logoSticky}' style='height: 25px' alt='logo' class='img-fluid'/></a></div>`
      })
    })

    /* -------------------------------------
    One PageNav Activation Code
    -------------------------------------- */
    import('../../theme/dependencies/jQuery-One-Page-Nav/js/jquery.nav.js').then(() => {
      $('#onepage_nav').onePageNav({
        currentClass: 'current',
        changeHash: true,
        scrollSpeed: 750,
        scrollThreshold: 0,
        filter: '',
        easing: 'swing',
        end: function () {
          $('.meanclose').trigger('click')
        }
      })
    })

    /* -------------------------------------
       Sidebar Toggle
    ------------------------------------- */
    $('#btn-toggle').on('click', function () {
      $('body').toggleClass('close-sidebar')
    })

    /* ---------------------------------------
    wow Settings
    --------------------------------------- */
    import('../../theme/dependencies/wow/wow.min.js').then(({ WOW }) => {
      const wow = new WOW({ live: false, mobile: false, offset: 0 })
      wow.init()
    })

    /* ---------------------------------------
    Background Parallax
    --------------------------------------- */
    import('../../theme/dependencies/parallaxie/parallaxie.js').then(() => {
      $('.parallaxie').parallaxie({
        speed: 0.5,
        offset: 0
      })
    })

    /* -------------------------------------
    Intersection Observer
    ------------------------------------- */
    if (window.IntersectionObserver) {
      const observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              entry.target.classList.add('active-animation')
              //  entry.target.src = entry.target.dataset.src;
              observer.unobserve(entry.target)
            }
          })
        },
        {
          rootMargin: '0px 0px -150px 0px'
        }
      )
      document.querySelectorAll('.has-animation').forEach(block => {
        observer.observe(block)
      })
    } else {
      document.querySelectorAll('.has-animation').forEach(block => {
        block.classList.remove('has-animation')
      })
    }

    /* -------------------------------------
    Contact Form initiating
    ------------------------------------- */
    import('../../theme/dependencies/validator/validator.min.js').then(() => {
      const contactForm = $('#contact-form')
      if (contactForm.length) {
        contactForm.validator().on('submit', function (e) {
          const $this = $(this)
          const $target = contactForm.find('.form-response')
          if (e.isDefaultPrevented()) {
            $target.html(
              "<div class='alert alert-success'><p>Please fill in all the required field.</p></div>"
            )
          } else {
            $.ajax({
              url: 'https://submit-form.com/dzDQR8BTy5N4GWB-pEv4J',
              type: 'POST',
              data: contactForm.serialize(),
              beforeSend: function () {
                $target.html("<div class='alert alert-info'><p>Loading ...</p></div>")
              },
              success: function (text) {
                $this[0].reset()
                $target.html(
                  "<div class='alert alert-success'><p>Thanks your message. I’ll get back to you shortly 👍</p></div>"
                )
              }
            })
            return false
          }
        })
      }
    })

    /* -------------------------------------
    Section background image
    ------------------------------------- */
    $('[data-bg-image]').each(function () {
      const img = $(this).data('bg-image')
      $(this).css({
        backgroundImage: 'url(' + img + ')'
      })
    })

    /* ---------------------------------------
    On Click Section Switch
    --------------------------------------- */
    $('[data-type="section-switch"]').on('click', function () {
      if (
        window.location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') &&
        window.location.hostname === this.hostname
      ) {
        let target = $(this.hash)
        if (target.length > 0) {
          target = target.length ? target : $('[name=' + this.hash.slice(1) + ']')
          $('html,body').animate(
            {
              scrollTop: target.offset().top
            },
            1000
          )
          return false
        }
      }
    })

    /* -------------------------------------
    On Scroll
    ------------------------------------- */
    $(window).on('scroll', function () {
      //  Sticky Menu
      if ($('header').hasClass('sticky-header')) {
        const targetScroll = 0
        const header = $('header')
        if ($(window).scrollTop() > targetScroll) {
          header.addClass('sticky')
        } else {
          header.removeClass('sticky')
        }
      }
    })

    /* -------------------------------------
     Gallery Popup
    ------------------------------------- */
    import('../../theme/dependencies/magnific-popup/js/jquery.magnific-popup.min.js').then(() => {
      $('.zoom-gallery').each(function () {
        $(this).magnificPopup({
          delegate: 'a.popup-zoom',
          type: 'image',
          gallery: {
            enabled: true
          }
        })
      })
    })

    /* --------------------------------------
    Isotope initialization
    -------------------------------------- */
    Promise.all([
      import('../../theme/dependencies/imagesloaded/js/imagesloaded.pkgd.min.js'),
      import('../../theme/dependencies/isotope-layout/js/isotope.pkgd.min.js'),
      import('../../theme/dependencies/magnific-popup/js/jquery.magnific-popup.min.js')
    ]).then(() => {
      const $container = $('.isotope-wrap')
      if ($container.length > 0) {
        //  eslint-disable-next-line no-inner-declarations
        function createGalleryPopup(selector) {
          selector = selector || ''
          $('.featuredContainer').magnificPopup({
            delegate: selector + ' a.popup-zoom',
            type: 'image',
            gallery: {
              enabled: true
            }
          })
        }

        let $isotope
        $('.featuredContainer', $container).imagesLoaded(function () {
          const selectero =
            $container.find('.isotope-classes-tab .nav-item:first-child').data('filter') || '*'

          $isotope = $('.featuredContainer', $container).isotope({
            filter: selectero,
            transitionDuration: '1s',
            hiddenStyle: {
              opacity: 0,
              transform: 'scale(0.001)'
            },
            visibleStyle: {
              transform: 'scale(1)',
              opacity: 1
            }
          })

          createGalleryPopup(selectero)
        })

        $container.find('.isotope-classes-tab').on('click', 'a', function () {
          const $this = $(this)
          $this.parent('.isotope-classes-tab').find('a').removeClass('current')
          $this.addClass('current')
          const selector = $this.attr('data-filter')
          $isotope.isotope({
            filter: selector
          })
          createGalleryPopup(selector)
          return false
        })
      }
    })

    /* -------------------------------------
    Counter
    ------------------------------------- */
    Promise.all([
      import('../../theme/dependencies/jquery.counterup/js/jquery.counterup.min.js'),
      import('../../theme/dependencies/waypoints/js/jquery.waypoints.min.js')
    ]).then(() => {
      const counterContainer = $('.counter')
      counterContainer.counterUp({
        delay: 50,
        time: 2000
      })
    })

    /* -------------------------------------
    Others
    ------------------------------------- */
    animateHeadline()
  }, [])
}

// Animated Headline
// Copy/paste of https://github.com/CodyHouse/animated-headline/blob/master/js/main.js
const animateHeadline = () => {
  // set animation timing
  const animationDelay = 2500
  // loading bar effect
  const barAnimationDelay = 3800
  const barWaiting = barAnimationDelay - 3000 // 3000 is the duration of the transition on the loading bar - set in the scss/css file
  // letters effect
  const lettersDelay = 50
  // type effect
  const typeLettersDelay = 150
  const selectionDuration = 500
  const typeAnimationDelay = selectionDuration + 800
  // clip effect
  const revealDuration = 600
  const revealAnimationDelay = 1500

  initHeadline()

  function initHeadline() {
    // insert <i> element for each letter of a changing word
    singleLetters($('.cd-headline.letters').find('b'))
    // initialise headline animation
    animateHeadline($('.cd-headline'))
  }

  function singleLetters($words) {
    $words.each(function () {
      const word = $(this)
      const letters = word.text().split('')
      const selected = word.hasClass('is-visible')

      for (const i in letters) {
        if (word.parents('.rotate-2').length > 0) letters[i] = '<em>' + letters[i] + '</em>'
        letters[i] = selected ? '<i class="in">' + letters[i] + '</i>' : '<i>' + letters[i] + '</i>'
      }

      const newLetters = letters.join('')
      word.html(newLetters).css('opacity', 1)
    })
  }

  function animateHeadline($headlines) {
    let duration = animationDelay
    $headlines.each(function () {
      const headline = $(this)

      if (headline.hasClass('loading-bar')) {
        duration = barAnimationDelay
        setTimeout(function () {
          headline.find('.cd-words-wrapper').addClass('is-loading')
        }, barWaiting)
      } else if (headline.hasClass('clip')) {
        const spanWrapper = headline.find('.cd-words-wrapper')
        const newWidth = spanWrapper.width() + 10
        spanWrapper.css('width', newWidth)
      } else if (!headline.hasClass('type')) {
        // assign to .cd-words-wrapper the width of its longest word
        const words = headline.find('.cd-words-wrapper b')
        let width = 0
        words.each(function () {
          const wordWidth = $(this).width()
          if (wordWidth > width) width = wordWidth
        })
        headline.find('.cd-words-wrapper').css('width', width)
      }

      // trigger animation
      setTimeout(function () {
        hideWord(headline.find('.is-visible').eq(0))
      }, duration)
    })
  }

  function hideWord($word) {
    const nextWord = takeNext($word)

    if ($word.parents('.cd-headline').hasClass('type')) {
      const parentSpan = $word.parent('.cd-words-wrapper')
      parentSpan.addClass('selected').removeClass('waiting')
      setTimeout(function () {
        parentSpan.removeClass('selected')
        $word
          .removeClass('is-visible')
          .addClass('is-hidden')
          .children('i')
          .removeClass('in')
          .addClass('out')
      }, selectionDuration)
      setTimeout(function () {
        showWord(nextWord, typeLettersDelay)
      }, typeAnimationDelay)
    } else if ($word.parents('.cd-headline').hasClass('letters')) {
      const bool = $word.children('i').length >= nextWord.children('i').length
      hideLetter($word.find('i').eq(0), $word, bool, lettersDelay)
      showLetter(nextWord.find('i').eq(0), nextWord, bool, lettersDelay)
    } else if ($word.parents('.cd-headline').hasClass('clip')) {
      $word.parents('.cd-words-wrapper').animate({ width: '2px' }, revealDuration, function () {
        switchWord($word, nextWord)
        showWord(nextWord)
      })
    } else if ($word.parents('.cd-headline').hasClass('loading-bar')) {
      $word.parents('.cd-words-wrapper').removeClass('is-loading')
      switchWord($word, nextWord)
      setTimeout(function () {
        hideWord(nextWord)
      }, barAnimationDelay)
      setTimeout(function () {
        $word.parents('.cd-words-wrapper').addClass('is-loading')
      }, barWaiting)
    } else {
      switchWord($word, nextWord)
      setTimeout(function () {
        hideWord(nextWord)
      }, animationDelay)
    }
  }

  function showWord($word, $duration) {
    if ($word.parents('.cd-headline').hasClass('type')) {
      showLetter($word.find('i').eq(0), $word, false, $duration)
      $word.addClass('is-visible').removeClass('is-hidden')
    } else if ($word.parents('.cd-headline').hasClass('clip')) {
      $word
        .parents('.cd-words-wrapper')
        .animate({ width: $word.width() + 10 }, revealDuration, function () {
          setTimeout(function () {
            hideWord($word)
          }, revealAnimationDelay)
        })
    }
  }

  function hideLetter($letter, $word, $bool, $duration) {
    $letter.removeClass('in').addClass('out')

    if (!$letter.is(':last-child')) {
      setTimeout(function () {
        hideLetter($letter.next(), $word, $bool, $duration)
      }, $duration)
    } else if ($bool) {
      setTimeout(function () {
        hideWord(takeNext($word))
      }, animationDelay)
    }

    if ($letter.is(':last-child') && $('html').hasClass('no-csstransitions')) {
      const nextWord = takeNext($word)
      switchWord($word, nextWord)
    }
  }

  function showLetter($letter, $word, $bool, $duration) {
    $letter.addClass('in').removeClass('out')

    if (!$letter.is(':last-child')) {
      setTimeout(function () {
        showLetter($letter.next(), $word, $bool, $duration)
      }, $duration)
    } else {
      if ($word.parents('.cd-headline').hasClass('type')) {
        setTimeout(function () {
          $word.parents('.cd-words-wrapper').addClass('waiting')
        }, 200)
      }
      if (!$bool) {
        setTimeout(function () {
          hideWord($word)
        }, animationDelay)
      }
    }
  }

  function takeNext($word) {
    return !$word.is(':last-child') ? $word.next() : $word.parent().children().eq(0)
  }

  function switchWord($oldWord, $newWord) {
    $oldWord.removeClass('is-visible').addClass('is-hidden')
    $newWord.removeClass('is-hidden').addClass('is-visible')
  }
}
