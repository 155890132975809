import React, { memo } from 'react'
import service1 from '../../theme/media/service/service1.png'
import service2 from '../../theme/media/service/service2.png'
import service3 from '../../theme/media/service/service3.png'

const Services = () => (
  <section className='fxt-service-section bg-color-1' id='services'>
    <div className='container'>
      <div className='fxt-heading-layout1'>
        <h2 className='heading-title'>What I do</h2>
        <div className='heading-paragraph'>I develop applications, and design solutions</div>
        <div className='heading-line' />
      </div>
      <div className='row'>
        <div className='col-lg-4 col-sm-6 col-12 has-animation'>
          <div className='translate-left-10 opacity-animation transition-200 transition-delay-100'>
            <div className='fxt-service-box-layout1'>
              <div className='item-img animation-delay0'>
                <img src={service2} alt='service' />
              </div>
              <div className='item-content'>
                <h3 className='item-title'>Web Development</h3>
                <p>
                  Typescript, HTML, CSS, PWA, Jamstack… I can help you build the app you{' '}
                  <em>need</em>. Just a tip: focus on the user first, and everything else follows.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-4 col-sm-6 col-12 has-animation'>
          <div className='translate-left-10 opacity-animation transition-200 transition-delay-400'>
            <div className='fxt-service-box-layout1'>
              <div className='item-img animation-delay1'>
                <img src={service3} alt='service' />
              </div>
              <div className='item-content'>
                <h3 className='item-title'>API Development</h3>
                <p>
                  APIs should be consistent, well-documented, and fast. Moreover it should sustain
                  future evolutions, avoiding breaking changes… That's the reason why I like
                  GraphQL.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-4 col-sm-6 col-12 has-animation'>
          <div className='translate-left-10 opacity-animation transition-200 transition-delay-700'>
            <div className='fxt-service-box-layout1'>
              <div className='item-img animation-delay2'>
                <img src={service1} alt='service' />
              </div>
              <div className='item-content'>
                <h3 className='item-title'>Performance by design</h3>
                <p>
                  Performance and stability are not an option. Besides it’s not <em>that</em>{' '}
                  complicated: most performance aspects can be tackled at design time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default memo(Services)
