import React, { memo } from 'react'
import figure2 from '../images/figure2.jpg'

const Testimonials = () => (
  <section className='fxt-testimonial-section parallaxie' data-bg-image={figure2}>
    <div className='container'>
      <div
        className='rc-carousel dot-control-layout1'
        data-loop='true'
        data-items='3'
        data-margin='50'
        data-autoplay='false'
        data-autoplay-timeout='3000'
        data-smart-speed='700'
        data-dots='true'
        data-nav='false'
        data-nav-speed='false'
        data-r-x-small='1'
        data-r-x-small-nav='false'
        data-r-x-small-dots='true'
        data-r-x-medium='1'
        data-r-x-medium-nav='false'
        data-r-x-medium-dots='true'
        data-r-small='1'
        data-r-small-nav='false'
        data-r-small-dots='true'
        data-r-medium='2'
        data-r-medium-nav='false'
        data-r-medium-dots='true'
        data-r-large='2'
        data-r-large-nav='false'
        data-r-large-dots='true'
        data-r-extra-large='3'
        data-r-extra-large-nav='false'
        data-r-extra-large-dots='true'
      >
        <div className='fxt-testimonial-box-layout1'>
          <div className='item-icon'>
            <i className='flaticon-right-quote' />
          </div>
          <div className='item-text'>
            I used to work with Vianney on several projects. He has great technical and
            communication/social skills, is very flexible and together we have managed to deliver
            challeging projects. If you want a reliable person, Vianney is the one to put on your
            team.
          </div>
          <div className='item-name'>
            Jochen Cordes <span>- PM Vodafone</span>
          </div>
        </div>

        <div className='fxt-testimonial-box-layout1'>
          <div className='item-icon'>
            <i className='flaticon-right-quote' />
          </div>
          <div className='item-text'>
            I am used to collaborating with Vianney on a huge project where his skills in web app
            architecture and JavaScript made the difference. Vianney is very involved in his
            project, very supportive. He pays attention to the quality of the final product. As a
            medical device software producer, the match was just perfect. I can only advise you to
            discuss the project with him: he will show you his open-mindedness, the first step
            toward the success.
          </div>
          <div className='item-name'>
            Charles Hebert <span>- CTO/CIO Pathoquest</span>
          </div>
        </div>

        <div className='fxt-testimonial-box-layout1'>
          <div className='item-icon'>
            <i className='flaticon-right-quote' />
          </div>
          <div className='item-text'>
            Vianney is a man of focus. A very diligent person when it comes to performance and User
            Experience. Working with him has been a blast. I could not recommend him too highly for
            Companies who are serious about getting their front-end job done in a scalable way and
            with little maintenance for the future.
          </div>
          <div className='item-name'>
            Guillaume Fourret
            <span>- Senior Developer Onepark</span>
          </div>
        </div>

        <div className='fxt-testimonial-box-layout1'>
          <div className='item-icon'>
            <i className='flaticon-right-quote' />
          </div>
          <div className='item-text'>
            Vianney sait être à la fois compétent et polyvalent. Lorsque j'ai un projet IT, je fais
            appel à lui sans hésiter. Que ce soit des questions de dévelopement, d'architecture ou
            encore de gestion technique, avoir un seul interlocuteur passionné qui maîtrise
            l'ensemble de la chaine est à la fois un atout majeur et un gain de temps.
          </div>
          <div className='item-name'>
            Alexandre Esparon <span>- Consultant CIO/CTO/DevOps/SRE</span>
          </div>
        </div>

        <div className='fxt-testimonial-box-layout1'>
          <div className='item-icon'>
            <i className='flaticon-right-quote' />
          </div>
          <div className='item-text'>
            Vianney did a really great job on my project. Clean and fast J2EE developments, so that
            he rapidly managed a small developers team. He is also calm and friendly, I'll work with
            him again with pleasure.
          </div>
          <div className='item-name'>
            Lionel Boutet <span>- PM Capgemini</span>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default memo(Testimonials)
