import React, { memo } from 'react'

const Pricing = () => (
  <section className='fxt-pricing-section bg-color-1' id='pricing'>
    <div className='container'>
      <div className='fxt-heading-layout1'>
        <h2 className='heading-title'>Pricing</h2>
        <div className='heading-paragraph'>
          Want to hire me for a full-time work <em>or</em> need a team of senior Web developers and
          designers?
        </div>
        <div className='heading-line' />
      </div>
      <div className='row justify-content-center'>
        <div className='col-lg-4 has-animation'>
          <div className='translate-left-10 opacity-animation transition-200 transition-delay-100'>
            <div className='fxt-pricing-box-layout1'>
              {/* <div className='pricing-amount'>
                <div className='item-price'>
                  €700<span>/day</span>
                </div>
              </div> */}
              <div className='pricing-title'>
                <h3 className='item-title'>Full-time Work</h3>
                <div className='item-subtitle'>Available for full time</div>
              </div>
              <div className='features-list'>
                <ul>
                  <li>Web Development</li>
                  <li>API Development</li>
                  <li>Team Leading</li>
                  <li>Agile Methods</li>
                  <li>Remote or Onsite (Paris, Île-de-France)</li>
                </ul>
              </div>
              <a href='#contact' className='item-btn btn-fill'>
                CONTACT ME
              </a>
            </div>
          </div>
        </div>
        <div className='col-lg-4 has-animation'>
          <div className='translate-left-10 opacity-animation transition-200 transition-delay-400'>
            <div className='fxt-pricing-box-layout1'>
              {/* <div className='pricing-amount'>
                <div className='item-price'>Contact Me</div>
              </div> */}
              <div className='pricing-title'>
                <h3 className='item-title'>Fixed Price Project</h3>
                <div className='item-subtitle'>Need a team for your needs?</div>
              </div>
              <div className='features-list'>
                <ul>
                  <li>Ui/Ux Design</li>
                  <li>Web Development</li>
                  <li>API Development</li>
                  <li>Android/iOS Development</li>
                  <li>Remote or Onsite (Paris, Île-de-France)</li>
                </ul>
              </div>
              <a href='#contact' className='item-btn btn-fill'>
                CONTACT ME
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default memo(Pricing)
