import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React, { memo } from 'react'

const About = ({ data }) => {
  const photoAbout = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "photo-about.jpg" }) {
        childImageSharp {
          fixed(quality: 90, width: 285, height: 285) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `).file.childImageSharp

  return (
    <section className='fxt-about-section bg-color-1' id='about'>
      <div className='container'>
        <div className='fxt-heading-layout1'>
          <h2 className='heading-title'>About Me</h2>
          <div className='heading-paragraph'>
            Dad, developper, passionate about science &amp; technologies.
            <br />I love making beautiful products, where simplicity is a rule of thumb.
          </div>
          <div className='heading-line' />
        </div>
        <div className='row'>
          <div className='col-lg-4 col-12 has-animation'>
            <div className='fxt-about-box-layout1'>
              <div className='translate-zoomout-10 opacity-animation transition-50 transition-delay-500'>
                <div className='item-img'>
                  <Img fixed={photoAbout.fixed} alt='Me' style={{ display: 'block' }} />
                </div>
              </div>
              <div className='translate-bottom-50 opacity-animation transition-150 transition-delay-1000'>
                <a
                  href='CV Vianney Carel.pdf'
                  className='download-btn btn-fill'
                  download
                  target='_blank'
                >
                  DOWNLOAD CV
                  <i className='flaticon-download' />
                </a>
              </div>
            </div>
          </div>
          <div className='col-lg-8 col-12'>
            <div className='fxt-about-box-layout2'>
              <h3 className='item-title'>Vianney Carel</h3>
              <div className='item-subtitle'>Full-stack Lead Developper</div>
              <p>
                I have more than 20 years of experience in programming. Starting with{' '}
                <a href='https://mo5.com/' rel='noreferrer noopener' target='_blank'>
                  Basic
                </a>{' '}
                when I was a child, I have a professional experience of C, Java, PL/SQL, Ruby,
                Python, Javascript, Typescript, CSS and HTML. I also experimented{' '}
                <a
                  href='https://www.google.com/search?sxsrf=ALeKk03nzB_f8_si8EcCJ5UGUHYZLJjUag:1590434424930&source=univ&tbm=isch&q=casio+calculators&safe=on&sa=X&ved=2ahUKEwiQn7733c_pAhUxUxUIHYm3Aw4QsAR6BAgFEAE&biw=2560&bih=1342'
                  rel='noreferrer noopener'
                  target='blank'
                >
                  Casio calculators
                </a>
                ,{' '}
                <a
                  href='https://en.wikipedia.org/wiki/HP_48_series'
                  rel='noreferrer noopener'
                  target='_blank'
                >
                  HP-48
                </a>
                ,{' '}
                <a
                  href='https://en.wikipedia.org/wiki/TI-92_series'
                  rel='noreferrer noopener'
                  target='_blank'
                >
                  TI-92
                </a>
                ,{' '}
                <a
                  href='https://en.wikipedia.org/wiki/Maple_(software)'
                  rel='noreferrer noopener'
                  target='_blank'
                >
                  Maple
                </a>
                , 68K Assembly, VHDL, C++, Perl… (not all by choice 🙃)
              </p>
              <p>
                Working as a freelancer since 2011, I now focus on Web development. Creating
                beautiful and helpful products is a major source of motivation and satisfaction to
                me.
              </p>
              <div className='personal-info'>
                <div className='row'>
                  <div className='col-md-6 col-12'>
                    <div className='info-list'>
                      <ul>
                        <li>
                          <span>City:</span>Paris, France
                        </li>
                        <li>
                          <span>Mail:</span>vianney.carel@logfuse.fr
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='col-md-6 col-12'>
                    <div className='info-list'>
                      <ul>
                        <li>
                          <span>Study:</span>ISEP
                        </li>
                        <li>
                          <span>Degree:</span>Master
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default memo(About)
