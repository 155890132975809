import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import React, { memo } from 'react'

const Progress = () => {
  const figure1 = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "figure1.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1368) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `).file.childImageSharp

  return (
    <BackgroundImage
      Tag='section'
      className='fxt-progress-section'
      fluid={figure1.fluid}
      backgroundColor='rgba(12, 17, 41, 0.7)'
      style={{ backgroundAttachment: 'fixed' }}
    >
      <div className='fxt-progress-section-tint-background'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-3 col-sm-6 col-12'>
              <div className='fxt-progress-box-layout1'>
                <h2 className='counting-text counter'>10</h2>
                <div className='item-label'>Happy Clients</div>
              </div>
            </div>
            <div className='col-lg-3 col-sm-6 col-12'>
              <div className='fxt-progress-box-layout1'>
                <h2 className='counting-text counter'>17</h2>
                <div className='item-label'>Projects</div>
              </div>
            </div>
            <div className='col-lg-3 col-sm-6 col-12'>
              <div className='fxt-progress-box-layout1'>
                <h2 className='counting-text counter'>4800</h2>
                <div className='item-label'>Cups of Coffee</div>
              </div>
            </div>
            <div className='col-lg-3 col-sm-6 col-12'>
              <div className='fxt-progress-box-layout1'>
                <h2 className='counting-text'>
                  &gt; <span className='counter'>100</span>k
                </h2>
                <div className='item-label'>Lines of Code</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default memo(Progress)
