import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import React, { memo } from 'react'

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      banner1: file(relativePath: { eq: "banner1.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1368) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      photoAbout: file(relativePath: { eq: "photo-about.jpg" }) {
        childImageSharp {
          fixed(quality: 90, width: 160, height: 160) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      Tag='section'
      className='fxt-main-banner-section'
      fluid={data.banner1.childImageSharp.fluid}
      backgroundColor='rgba(27, 33, 64, 0.85)'
    >
      <div className='fxt-main-banner-section-tint-background'>
        <div className='container'>
          <div className='fxt-main-banner-box-layout1 has-animation'>
            <div className='top-content'>
              <div className='item-img'>
                <div className='translate-zoomout-10 opacity-animation transition-50 transition-delay-100'>
                  <Img fixed={data.photoAbout.childImageSharp.fixed} alt='Me' />
                </div>
              </div>
              <div className='item-content'>
                <div className='translate-bottom-100 opacity-animation transition-150 transition-delay-500'>
                  <div className='item-top-text'>Hello</div>
                </div>
                <div className='translate-bottom-100 opacity-animation transition-150 transition-delay-800'>
                  <h1 className='item-title'>I’m Vianney Carel</h1>
                </div>
                <div className='translate-bottom-100 opacity-animation transition-150 transition-delay-1100'>
                  <div className='cd-intro'>
                    <h2 className='small-text cd-headline clip'>
                      <span className='item-subtitle'>A Freelance</span>{' '}
                      <span className='cd-words-wrapper'>
                        <b className='item-subtitle is-visible'>Creative Coder</b>
                        <b>Full-stack Developer</b>
                        <b>
                          who{' '}
                          <span role='img' aria-label='loves'>
                            ❤️
                          </span>{' '}
                          Web Design
                        </b>
                      </span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className='bottom-content'>
              <div className='item-social'>
                <ul>
                  <li>
                    <div className='translate-bottom-100 opacity-animation transition-50 transition-delay-1300'>
                      <a href='https://github.com/vcarel' target='_blank' rel='noopener noreferrer'>
                        <i className='fab fa-github' />
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className='translate-bottom-100 opacity-animation transition-50 transition-delay-1500'>
                      <a
                        href='https://stackoverflow.com/users/1202385/vcarel'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <i className='fab fa-stack-overflow' />
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className='translate-bottom-100 opacity-animation transition-50 transition-delay-1700'>
                      <a
                        href='https://www.linkedin.com/in/vianneycarel'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <i className='fab fa-linkedin' />
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
              <div className='item-website'>
                <div className='translate-bottom-100 opacity-animation transition-50 transition-delay-2400'>
                  <a href='https://vianneycarel.me'>vianneycarel.me</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default memo(Banner)
